import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, makeEnvironmentProviders } from '@angular/core';
import { DefaultTranspiler, isObject, getValue, setValue, TRANSLOCO_TRANSPILER } from '@jsverse/transloco';
import MessageFormat from '@messageformat/core';
const TRANSLOCO_MESSAGE_FORMAT_CONFIG = new InjectionToken('TRANSLOCO_MESSAGE_FORMAT_CONFIG');
function defaultFactory(locales, messageConfig) {
  return new MessageFormat(locales, messageConfig);
}
function cachedFactory(locales, messageConfig) {
  const mf = defaultFactory(locales, messageConfig);
  const original = mf.compile;
  const cache = new Map();
  const localeKey = `__${locales?.toString() || MessageFormat.defaultLocale}__`;
  mf.compile = function (messages) {
    const cacheKey = `${localeKey}${messages}`;
    const cachedMsg = cache.get(cacheKey);
    if (cachedMsg) {
      return cachedMsg;
    }
    const msg = original.call(this, messages);
    cache.set(cacheKey, msg);
    return msg;
  };
  return mf;
}
class MessageFormatTranspiler extends DefaultTranspiler {
  messageFormat;
  messageConfig;
  mfFactory;
  constructor(config) {
    super();
    const {
      locales,
      enableCache = true,
      ...messageConfig
    } = {
      locales: null,
      ...config
    };
    this.messageConfig = messageConfig;
    this.mfFactory = enableCache ? cachedFactory : defaultFactory;
    this.messageFormat = this.mfFactory(locales, messageConfig);
  }
  transpile({
    value,
    params = {},
    translation,
    key
  }) {
    if (!value) {
      return value;
    }
    if (isObject(value) && params) {
      Object.keys(params).forEach(p => {
        const transpiled = super.transpile({
          value: getValue(value, p),
          params: getValue(params, p),
          translation,
          key
        });
        const message = this.messageFormat.compile(transpiled);
        value = setValue(value, p, message(params[p]));
      });
    } else if (!Array.isArray(value)) {
      const transpiled = super.transpile({
        value,
        params,
        translation,
        key
      });
      const message = this.messageFormat.compile(transpiled);
      return message(params);
    }
    return value;
  }
  onLangChanged(lang) {
    this.setLocale(lang);
  }
  setLocale(locale) {
    this.messageFormat = this.mfFactory(locale, this.messageConfig);
  }
  static ɵfac = function MessageFormatTranspiler_Factory(t) {
    return new (t || MessageFormatTranspiler)(i0.ɵɵinject(TRANSLOCO_MESSAGE_FORMAT_CONFIG, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MessageFormatTranspiler,
    factory: MessageFormatTranspiler.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessageFormatTranspiler, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [TRANSLOCO_MESSAGE_FORMAT_CONFIG]
    }]
  }], null);
})();
function provideTranslocoMessageformat(config) {
  return makeEnvironmentProviders([{
    provide: TRANSLOCO_MESSAGE_FORMAT_CONFIG,
    useValue: config
  }, {
    provide: TRANSLOCO_TRANSPILER,
    useClass: MessageFormatTranspiler
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { MessageFormatTranspiler, TRANSLOCO_MESSAGE_FORMAT_CONFIG, provideTranslocoMessageformat };

import * as i0 from '@angular/core';
import { booleanAttribute, Component, Input, HostBinding } from '@angular/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
export { crmMapOptions, crmResolveOptionId } from 'common-module/core/types';
class CrmLoadingComponent {
  constructor() {
    this.size = 'large';
    this.tip = 'crm.loading.tip';
    this.spinning = true;
    this.fullscreen = false;
    this.hostClass = 'crm-loading';
  }
  static {
    this.ɵfac = function CrmLoadingComponent_Factory(t) {
      return new (t || CrmLoadingComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmLoadingComponent,
      selectors: [["crm-loading"]],
      hostVars: 4,
      hostBindings: function CrmLoadingComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
          i0.ɵɵclassProp("crm-loading--fullscreen", ctx.fullscreen);
        }
      },
      inputs: {
        size: "size",
        tip: "tip",
        spinning: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "spinning", "spinning", booleanAttribute],
        fullscreen: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "fullscreen", "fullscreen", booleanAttribute]
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 5,
      consts: [["nzSimple", "", 3, "nzSize", "nzSpinning", "nzTip"]],
      template: function CrmLoadingComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "nz-spin", 0);
          i0.ɵɵpipe(1, "crmTranslate");
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzSize", ctx.size)("nzSpinning", ctx.spinning)("nzTip", ctx.tip ? i0.ɵɵpipeBind1(1, 3, ctx.tip) : null);
        }
      },
      dependencies: [NzSpinComponent, CrmTranslatePipe],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmLoadingComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-loading',
      imports: [NzSpinComponent, CrmTranslatePipe],
      template: `
    <nz-spin
      nzSimple
      [nzSize]="size"
      [nzSpinning]="spinning"
      [nzTip]="tip ? (tip | crmTranslate) : null"
    ></nz-spin>
  `
    }]
  }], null, {
    size: [{
      type: Input
    }],
    tip: [{
      type: Input
    }],
    spinning: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    fullscreen: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }, {
      type: HostBinding,
      args: ['class.crm-loading--fullscreen']
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmLoadingComponent };

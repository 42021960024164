import * as i0 from '@angular/core';
import { inject, ElementRef, Renderer2, Component, ChangeDetectionStrategy, HostBinding, InjectionToken, Directive, Input, NgModule, DestroyRef, Injector, forwardRef, signal, computed, ViewContainerRef, isSignal, Pipe, TemplateRef, Injectable, effect } from '@angular/core';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { capitalize, isNil, cloneDeep, get, omit, set, isEmpty, pick } from 'lodash-es';
import * as i1 from 'ng-zorro-antd/grid';
import { NzColDirective } from 'ng-zorro-antd/grid';
import { toObservable, takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, NgModel, NgControl, FormControl, FormControlDirective, FormControlName } from '@angular/forms';
import { takeUntil, filter, map, catchError, share, debounceTime } from 'rxjs/operators';
import { Subject, fromEvent, of, forkJoin, EMPTY, isObservable, take } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { HttpParams, HttpClient, HttpClientModule } from '@angular/common/http';
import { wrapIntoObservable } from 'ng-zorro-antd/core/util';
import { Location } from '@angular/common';
class CrmAppComponent {
  constructor() {
    this.defaultClass = 'crm-app';
    this.el = inject(ElementRef);
    this.renderer = inject(Renderer2);
    this.deviceDetector = inject(DeviceDetectorService);
  }
  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, `crm-app--${this.deviceDetector.deviceType}`);
  }
  static {
    this.ɵfac = function CrmAppComponent_Factory(t) {
      return new (t || CrmAppComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmAppComponent,
      selectors: [["crm-app"]],
      hostVars: 2,
      hostBindings: function CrmAppComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.defaultClass);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 0,
      template: function CrmAppComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "router-outlet");
        }
      },
      dependencies: [RouterOutlet],
      styles: ["[_nghost-%COMP%]{display:block;height:100%}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmAppComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-app',
      template: `<router-outlet></router-outlet>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [RouterOutlet],
      styles: [":host{display:block;height:100%}\n"]
    }]
  }], null, {
    defaultClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Main app configuration token
 */
const CrmAppConfig = new InjectionToken('CrmAppConfiguration');

/**
 * Dynamic configuration token
 */
const CrmDynamicConfigToken = new InjectionToken('CrmDynamicConfigToken');

/**
 * Module config token, overrides module configuration in main app configuration
 * Use property multi: true to merge module configurations in runtime.
 */
const CrmModuleConfigToken = new InjectionToken('CrmModuleConfigToken');
const getNzBreakPoint = index => {
  switch (index) {
    case 'xxl':
      return 'nzXXl';
    default:
      return `nz${capitalize(index)}`;
  }
};
/**
 * Breakpoint directive to set all breakpoints form nz-col directive without need
 * to define them all in template
 *
 * Example:
 *
 * This
 * ```html
 * <div
 *  nz-col
 *  [nzXs]="pane?.col?.xs || (pane?.col | toString)"
 *  [nzSm]="pane?.col?.sm || (pane?.col | toString)"
 *  [nzMd]="pane?.col?.md || (pane?.col | toString)"
 *  [nzLg]="pane?.col?.lg || (pane?.col | toString)"
 *  [nzXl]="pane?.col?.xl || (pane?.col | toString)"
 *  [nzXXl]="pane?.col?.xxl || (pane?.col | toString)"
 * >
 * ```
 *
 *   will be replaced with ```html <div crmBreakpointSpan [breakpointConfig]="{ xs: 24, lg: 8 }">```.
 */
class CrmBreakpointSpanDirective {
  constructor(colDir) {
    this.colDir = colDir;
    this.offset = 0;
    this.defaultValue = 24;
  }
  ngOnInit() {
    this.setColDirective(this.breakpointConfig, this.offset, this.defaultValue);
    this.colDir.ngOnInit();
  }
  ngOnChanges() {
    this.ngOnInit();
  }
  ngAfterViewInit() {
    this.colDir.ngAfterViewInit();
  }
  ngOnDestroy() {
    this.colDir.ngOnDestroy();
  }
  setColDirective(breakpointConfig, offset, defaultValue) {
    this.colDir.nzOffset = offset ?? 0;
    if (!breakpointConfig) {
      if (!isNil(defaultValue)) {
        this.colDir.nzSpan = defaultValue;
      }
      return;
    }
    if (typeof breakpointConfig === 'number') {
      this.colDir.nzSpan = breakpointConfig;
      return;
    }
    const config = Object.entries(breakpointConfig);
    config.forEach(([bp, span]) => {
      this.colDir[getNzBreakPoint(bp)] = span ?? defaultValue;
    });
  }
  static {
    this.ɵfac = function CrmBreakpointSpanDirective_Factory(t) {
      return new (t || CrmBreakpointSpanDirective)(i0.ɵɵdirectiveInject(i1.NzColDirective));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmBreakpointSpanDirective,
      selectors: [["", "crmBreakpointSpan", ""]],
      inputs: {
        breakpointConfig: "breakpointConfig",
        offset: "offset",
        defaultValue: "defaultValue"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([NzColDirective]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmBreakpointSpanDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[crmBreakpointSpan]',
      providers: [NzColDirective]
    }]
  }], () => [{
    type: i1.NzColDirective
  }], {
    breakpointConfig: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    defaultValue: [{
      type: Input
    }]
  });
})();
/**
 * @deprecated
 */
class CrmBreakpointSpanModule {
  static {
    this.ɵfac = function CrmBreakpointSpanModule_Factory(t) {
      return new (t || CrmBreakpointSpanModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmBreakpointSpanModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmBreakpointSpanModule, [{
    type: NgModule,
    args: [{
      imports: [CrmBreakpointSpanDirective],
      exports: [CrmBreakpointSpanDirective]
    }]
  }], null, null);
})();
class CrmUnsubscribeDirective {
  constructor() {
    this.destroyed$ = new Subject();
    this.destroyRef = inject(DestroyRef);
    this.injector = inject(Injector);
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  /**
   * Pipe observable from argument with {@link takeUntil} operator
   * with {@link CrmUnsubscribeDirective.destroyed$} subject that fires
   * on component destroy.
   *
   * @param obs$
   * @protected
   */
  untilDestroyed(obs$) {
    return obs$.pipe(takeUntil(this.destroyed$));
  }
  static {
    this.ɵfac = function CrmUnsubscribeDirective_Factory(t) {
      return new (t || CrmUnsubscribeDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmUnsubscribeDirective
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmUnsubscribeDirective, [{
    type: Directive
  }], null, null);
})();

/**
 * Factory function for Value accessor provider for components extending CrmControlValueAccessorDirective.
 *
 * @param component
 */
const crmControlValueAccessorFactory = component => {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true
  };
};
class CrmControlValueAccessorDirective extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    this.status = signal('');
    /**
     * @deprecated
     *
     * @see {status}
     */
    this.status$ = toObservable(computed(this.status));
    this.valueSignal = signal(this.defaultValue);
  }
  get value() {
    return this.valueSignal();
  }
  set value(value) {
    this.valueSignal.set(value);
  }
  ngOnInit() {
    this.initControl();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  writeValue(obj) {
    this.value = obj;
    if (this.ngControl instanceof NgModel) {
      this.control.setValue(obj, {
        emitEvent: false
      });
    }
  }
  handleChange(value) {
    this.change(value ?? this.defaultValue);
  }
  change(value) {
    this.value = value;
    this.onChange?.(this.value);
  }
  initControl() {
    const ngControl = this.injector.get(NgControl, null);
    if (!ngControl) {
      return;
    }
    this.ngControl = ngControl;
    if (ngControl instanceof NgModel) {
      this.value = ngControl.model;
      this.control = new FormControl(this.value);
      this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => this.handleChange(value));
    } else if (ngControl instanceof FormControlDirective) {
      this.control = ngControl.control;
    } else if (ngControl instanceof FormControlName && ngControl.name) {
      this.control = ngControl.formDirective?.form?.controls?.[ngControl.name];
    }
    this.initStatus();
  }
  initStatus() {
    if (!this.control) {
      return;
    }
    this.control.statusChanges.pipe(filter(() => this.control.dirty), map(status => {
      switch (status) {
        case 'INVALID':
          return 'error';
        default:
          return '';
      }
    }), takeUntil(this.destroyed$)).subscribe(status => this.status.set(status));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmControlValueAccessorDirective_BaseFactory;
      return function CrmControlValueAccessorDirective_Factory(t) {
        return (ɵCrmControlValueAccessorDirective_BaseFactory || (ɵCrmControlValueAccessorDirective_BaseFactory = i0.ɵɵgetInheritedFactory(CrmControlValueAccessorDirective)))(t || CrmControlValueAccessorDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmControlValueAccessorDirective,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmControlValueAccessorDirective, [{
    type: Directive
  }], null, null);
})();
const DISABLED_ATTR = 'disabled';
/**
 * Directive to disable element and all its children, and set tooltip for it.
 */
class CrmDisabledDirective {
  constructor() {
    this.nzTooltipDirective = inject(NzTooltipDirective);
    this._elements = [];
    this.sanitizer = inject(DomSanitizer);
    this.viewContainer = inject(ViewContainerRef);
    this.el = inject(ElementRef);
    this.nzTooltipDirective.title = '';
  }
  get disabledStyle() {
    return this.isDisabled ? this.sanitizer.bypassSecurityTrustStyle('opacity: 0.6; cursor: auto;') : '';
  }
  ngOnInit() {
    this.nzTooltipDirective.title = (this.isDisabled ? this.disabledTooltip : this.enabledTooltip) ?? '';
    this.nzTooltipDirective.placement = this.tooltipPlacement ?? 'top';
  }
  ngOnDestroy() {
    this.nzTooltipDirective.ngOnDestroy();
  }
  ngOnChanges(changes) {
    if (changes.isDisabled && !changes.isDisabled?.firstChange) {
      this.handleDisabledState(changes.isDisabled.currentValue);
    }
  }
  ngAfterViewInit() {
    this.nzTooltipDirective.ngAfterViewInit();
    this._elements = this.viewContainer.element.nativeElement.querySelectorAll('*');
    this.handleDisabledState(this.isDisabled);
  }
  handleDisabledState(disabled) {
    this.setDisabledChildren(disabled);
    if (disabled) {
      this.setTooltipTitle(this.disabledTooltip ?? '');
    } else {
      this.setTooltipTitle(this.enabledTooltip ?? '');
    }
    this.nzTooltipDirective.ngAfterViewInit();
  }
  setDisabledChildren(disabled) {
    const elements = this._elements ?? [];
    [this.el.nativeElement, ...elements].forEach(e => disabled ? e.setAttribute(DISABLED_ATTR, '') : e.removeAttribute(DISABLED_ATTR));
  }
  setTooltipTitle(title) {
    this.nzTooltipDirective.title = title;
    this.nzTooltipDirective.directiveTitle = title;
  }
  static {
    this.ɵfac = function CrmDisabledDirective_Factory(t) {
      return new (t || CrmDisabledDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmDisabledDirective,
      selectors: [["", "crmDisabled", ""]],
      hostVars: 2,
      hostBindings: function CrmDisabledDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleMap(ctx.disabledStyle);
        }
      },
      inputs: {
        disabledTooltip: "disabledTooltip",
        enabledTooltip: "enabledTooltip",
        tooltipPlacement: "tooltipPlacement",
        isDisabled: "isDisabled"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([NzTooltipDirective]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmDisabledDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[crmDisabled]',
      providers: [NzTooltipDirective]
    }]
  }], () => [], {
    disabledTooltip: [{
      type: Input
    }],
    enabledTooltip: [{
      type: Input
    }],
    tooltipPlacement: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    disabledStyle: [{
      type: HostBinding,
      args: ['style']
    }]
  });
})();
/**
 * @deprecated
 */
class CrmDisabledDirectiveModule {
  static {
    this.ɵfac = function CrmDisabledDirectiveModule_Factory(t) {
      return new (t || CrmDisabledDirectiveModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmDisabledDirectiveModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmDisabledDirectiveModule, [{
    type: NgModule,
    args: [{
      imports: [CrmDisabledDirective],
      exports: [CrmDisabledDirective]
    }]
  }], null, null);
})();
const toArray = array => {
  return Array.isArray(array) ? array : [array];
};
const joinArrayProperties = property => {
  return toArray(property).join('.');
};

/**
 * Merge two objects to one.
 * Does not mutate object.
 *
 * @param target
 * @param source
 * @returns
 */
const mergeDeep = (target, source) => {
  const innerMergeDeep = (_target, _source) => {
    const result = {
      ...cloneDeep(_target ?? {})
    };
    const sourceEntries = Object.entries(_source ?? {});
    for (const entry of sourceEntries) {
      const [entryKey, entryValue] = entry;
      if (typeof entryValue !== 'object' || Array.isArray(entryValue) || entryValue == null) {
        result[entryKey] = entryValue;
      } else {
        result[entryKey] = innerMergeDeep(_target?.[entryKey] ?? {}, entryValue);
      }
    }
    return result;
  };
  return innerMergeDeep(target, source);
};

/**
 * Transforms object from object with dot notation keys to
 * object with nested objects.
 *
 * Example:
 * Input
 * {
 *   'meta.data': 'some data',
 *   name: 'Foo Boo'
 * }
 *
 * Output
 * {
 *   name: 'Foo Boo',
 *   meta: {
 *     data: 'some data'
 *   }
 * }
 *
 * @param data
 * @param options
 */
const transformObject = (data, options) => {
  if (!data) {
    return {};
  }
  const {
    omitEmpty = false
  } = options ?? {};
  return Object.keys(data).reduce((p, key) => {
    if (key.split('.').length) {
      return mergeDeep(p, getNestedObject(data, key, key, omitEmpty));
    }
    return {
      ...p,
      [key]: data[key]
    };
  }, {});
};
/**
 * Create Nested object from dot notation object keys.
 * Example:
 * Input
 * ```
 * var obj = {
 *   'meta.data': 98
 * };
 * ```
 * Output
 * ```
 * var res = {
 *   meta: {
 *     data: 98;
 *   }
 * };
 * @param obj           Any object.
 * @param key           Object key, that is in form of `meta.data`.
 * @param originalKey   Original key from object for data to be extracted from to lowest level.
 * @param omitEmpty     Omits empty values
 */
const getNestedObject = (obj, key, originalKey, omitEmpty = false) => {
  const [k, ...keys] = key.split('.');
  const o = {};
  if (keys.length === 0) {
    if (omitEmpty && !(originalKey in obj)) {
      return o;
    }
    const objVal = obj[originalKey];
    if (objVal && typeof objVal === 'object' && !Array.isArray(objVal) && Object.keys(objVal).length) {
      o[k] = transformObject(objVal);
    } else {
      o[k] = obj[originalKey];
    }
    return o;
  } else {
    o[k] = getNestedObject(obj, keys.join('.'), originalKey, omitEmpty);
    return o;
  }
};
const toBoolean = () => {
  return source => source.pipe(map(val => !!val));
};
const windowResize$ = fromEvent(window, 'resize').pipe(map(() => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}));
const crmRemovePunctuation = value => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
const crmIsMatchingString = (a, b) => {
  return !!crmRemovePunctuation(a.toLowerCase()).match(crmRemovePunctuation(b.toLowerCase()));
};

/**
 * Helper function for merge multiple request with forkJoin
 *
 * @example
 * collectResponse((id) => this.productModule.getProduct(id), ['_1', '_2', '_3']).subscribe(response => {
 *   console.log(response[0]) // { _id: '_1', name: 'Name 1', ...}
 *   console.log(response[1]) // { _id: '_2', name: 'Name 2', ...}
 *   console.log(response[2]) // { _id: '_3', name: 'Name 3', ...}
 * })
 *
 * @param obs$ {Observable} - function which returns object found by ID
 * @param objects {Array} - array of IDs which are requested by obs$ function
 * @param defaultValue
 */
const crmCollectResponse = (obs$, objects, defaultValue) => {
  if (!objects || objects.length === 0) {
    return of([]);
  }
  return forkJoin(objects.map((data, index) => obs$(data, index).pipe(catchError(() => of(defaultValue ?? {})))));
};
const crmCollectResponseMap = (obs$, objects, bindProp = '_id', defaultValue) => {
  if (!objects || objects.length === 0) {
    return of({});
  }
  const forkJoinInput = objects.reduce((input, o) => {
    input[get(o, bindProp)] = obs$(o).pipe(catchError(_ => of(defaultValue ?? {})));
    return input;
  }, {});
  return forkJoin(forkJoinInput);
};
class CrmHttpParamTransform {
  static transform(params, encoder) {
    if (!params) {
      return undefined;
    }
    if (typeof params === 'string') {
      const paramValues = params.split('&').filter(p => p.indexOf('total') === -1);
      return paramValues.reduce((p, c) => {
        const [key, value] = c.split('=');
        return p.append(CrmHttpParamTransform.transformPaginationKey(key), value);
      }, new HttpParams({
        encoder
      }));
    }
    const transform = omit(params ?? {}, 'total');
    return this.objectToParams(transform, encoder);
  }
  static objectToParams(object, encoder) {
    return Object.keys(object).reduce((p, c) => {
      return p.append(CrmHttpParamTransform.transformPaginationKey(c), object[c]);
    }, new HttpParams({
      encoder
    }));
  }
  static transformPaginationKey(key) {
    switch (key) {
      case 'limit':
      case 'skip':
        return `$${key}`;
      default:
        return key;
    }
  }
}
const crmKillEvent = event => {
  event?.preventDefault?.();
  event?.stopPropagation?.();
};

/**
 * Resolve expression to observable
 *
 * @param input
 */
const crmResolveExpression = input => {
  const {
    resolvable,
    source
  } = input;
  let observable;
  if (resolvable == null) {
    observable = input.initialValue ? of(input.initialValue) : EMPTY;
  } else if (isObservable(resolvable)) {
    observable = resolvable;
  } else if (isSignal(resolvable)) {
    observable = toObservable(resolvable, {
      injector: input.injector
    });
  } else if (typeof resolvable === 'function') {
    const resolved = resolvable(source);
    if (isObservable(resolved)) {
      observable = resolved;
    } else if (isSignal(resolved)) {
      observable = toObservable(resolved, {
        injector: input.injector
      });
    }
    observable = wrapIntoObservable(resolved);
  } else {
    observable = wrapIntoObservable(resolvable);
  }
  if (input.destroy) {
    return observable.pipe(takeUntilDestroyed(input.destroy));
  }
  if (input.destroy$) {
    return observable.pipe(takeUntil(input.destroy$));
  }
  return observable;
};
/**
 * Resolve expression as signal
 *
 * @param input
 */
const crmResolveSignal = input => {
  return toSignal(crmResolveExpression({
    resolvable: input.resolvable,
    source: input.source,
    injector: input.injector,
    destroy: input.destroy ?? input.injector.get(DestroyRef)
  }), {
    injector: input.injector,
    initialValue: input.initialValue
  });
};

/* eslint-enable @typescript-eslint/no-explicit-any */

const crmFilterNullish = () => filter(x => x != null);

/**
 * Custom subject which emits only one value then completes
 */
class CrmOnceSubject extends Subject {
  asObservable() {
    return super.asObservable().pipe(take(1));
  }
}

/**
 * Rxjs Subject wrapper for shared event emitting for all observers.
 *
 * Created for reload subscriptions in data provider.
 * Share subject will fire all observers with only one side effect, unlike standard Subject
 * that fires side effect for every observer separately.
 *
 * Generic type T is for type of output data of observable stream. Default `SafeAny`
 */
class CrmSharedSubject {
  constructor(fromObservable) {
    this._subject = new Subject();
    this._sharedObservable = (fromObservable || this._subject).pipe(share());
  }
  /**
   * Pass through method to call `Subject.next` method.
   *
   * @param value
   */
  next(value) {
    this._subject.next(value);
  }
  /**
   * Pipes rxjs operators to shared subject, similar to standard `subject.pipe` method.
   * Returns rxjs Observable.
   *
   * @param withOperators
   */
  pipe(withOperators) {
    let observable = this._sharedObservable;
    if (!withOperators || !withOperators.length) {
      return observable;
    }
    withOperators.forEach(op => {
      observable = observable.pipe(op);
    });
    return observable;
  }
}

/**
 * Pipe for array slice
 */
class CrmSlicePipe {
  transform(array, count) {
    return array.slice(0, count);
  }
  static {
    this.ɵfac = function CrmSlicePipe_Factory(t) {
      return new (t || CrmSlicePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmSlice",
      type: CrmSlicePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmSlicePipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmSlice'
    }]
  }], null, null);
})();
/**
 * Return rest of array after slice
 */
class CrmSliceRestPipe {
  transform(array, count) {
    return array.slice(count);
  }
  static {
    this.ɵfac = function CrmSliceRestPipe_Factory(t) {
      return new (t || CrmSliceRestPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmSliceRest",
      type: CrmSliceRestPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmSliceRestPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmSliceRest'
    }]
  }], null, null);
})();
class CrmReversePipe {
  transform(value) {
    return value.reverse();
  }
  static {
    this.ɵfac = function CrmReversePipe_Factory(t) {
      return new (t || CrmReversePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmReverse",
      type: CrmReversePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmReversePipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmReverse'
    }]
  }], null, null);
})();
class CrmIsLastPipe {
  transform(item, value) {
    const index = value.indexOf(item);
    return index === value.length - 1;
  }
  static {
    this.ɵfac = function CrmIsLastPipe_Factory(t) {
      return new (t || CrmIsLastPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsLast",
      type: CrmIsLastPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsLastPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsLast'
    }]
  }], null, null);
})();
class CrmFilterPipe {
  transform(value, property, defaultValue = true, data) {
    if (!value) {
      return [];
    }
    return value.filter(v => v[property] && defaultValue && data);
  }
  static {
    this.ɵfac = function CrmFilterPipe_Factory(t) {
      return new (t || CrmFilterPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmFilter",
      type: CrmFilterPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFilterPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmFilter'
    }]
  }], null, null);
})();
/**
 * @deprecated
 */
class CrmArrayPipesModule {
  static {
    this.ɵfac = function CrmArrayPipesModule_Factory(t) {
      return new (t || CrmArrayPipesModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmArrayPipesModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmArrayPipesModule, [{
    type: NgModule,
    args: [{
      imports: [CrmSlicePipe, CrmSliceRestPipe, CrmReversePipe, CrmIsLastPipe, CrmFilterPipe],
      exports: [CrmSlicePipe, CrmSliceRestPipe, CrmReversePipe, CrmIsLastPipe, CrmFilterPipe]
    }]
  }], null, null);
})();
class CrmBypassHtmlPipe {
  constructor() {
    this.sanitizer = inject(DomSanitizer);
  }
  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  static {
    this.ɵfac = function CrmBypassHtmlPipe_Factory(t) {
      return new (t || CrmBypassHtmlPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmBypassHtml",
      type: CrmBypassHtmlPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmBypassHtmlPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmBypassHtml'
    }]
  }], null, null);
})();
class CrmElementIdPipe {
  transform(value, baseString) {
    return `${baseString}_${(value ?? '').replace(/[.]/g, '_')}`;
  }
  static {
    this.ɵfac = function CrmElementIdPipe_Factory(t) {
      return new (t || CrmElementIdPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmElementId",
      type: CrmElementIdPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmElementIdPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmElementId'
    }]
  }], null, null);
})();
/**
 * @deprecated
 */
class CrmElementIdPipeModule {
  static {
    this.ɵfac = function CrmElementIdPipeModule_Factory(t) {
      return new (t || CrmElementIdPipeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmElementIdPipeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmElementIdPipeModule, [{
    type: NgModule,
    args: [{
      imports: [CrmElementIdPipe],
      exports: [CrmElementIdPipe]
    }]
  }], null, null);
})();
class CrmInitialsPipe {
  transform(value, count = 2) {
    if (!value) {
      return value;
    }
    return value.split(/\s+/).slice(0, count).map(i => i[0]?.toUpperCase() ?? '').join('');
  }
  static {
    this.ɵfac = function CrmInitialsPipe_Factory(t) {
      return new (t || CrmInitialsPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmInitials",
      type: CrmInitialsPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmInitialsPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmInitials'
    }]
  }], null, null);
})();
/**
 * @deprecated
 */
class CrmInitialsPipeModule {
  static {
    this.ɵfac = function CrmInitialsPipeModule_Factory(t) {
      return new (t || CrmInitialsPipeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmInitialsPipeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmInitialsPipeModule, [{
    type: NgModule,
    args: [{
      imports: [CrmInitialsPipe],
      exports: [CrmInitialsPipe]
    }]
  }], null, null);
})();
class CrmMergeObjectsPipe {
  transform(value, mergeWith) {
    return {
      ...value,
      ...mergeWith
    };
  }
  static {
    this.ɵfac = function CrmMergeObjectsPipe_Factory(t) {
      return new (t || CrmMergeObjectsPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmMergeObjects",
      type: CrmMergeObjectsPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmMergeObjectsPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmMergeObjects'
    }]
  }], null, null);
})();
class CrmObjectValuePipe {
  transform(value, dataIndex, defaultValue) {
    if (!value || !dataIndex) {
      return undefined;
    }
    return get(value, dataIndex, defaultValue);
  }
  static {
    this.ɵfac = function CrmObjectValuePipe_Factory(t) {
      return new (t || CrmObjectValuePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmObjectValue",
      type: CrmObjectValuePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmObjectValuePipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmObjectValue'
    }]
  }], null, null);
})();
class CrmOmitPipe {
  transform(value, keys) {
    return omit(value, keys);
  }
  static {
    this.ɵfac = function CrmOmitPipe_Factory(t) {
      return new (t || CrmOmitPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmOmit",
      type: CrmOmitPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmOmitPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmOmit'
    }]
  }], null, null);
})();
/**
 * @deprecated
 */
class CrmObjectsPipe {
  static {
    this.ɵfac = function CrmObjectsPipe_Factory(t) {
      return new (t || CrmObjectsPipe)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmObjectsPipe
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmObjectsPipe, [{
    type: NgModule,
    args: [{
      imports: [CrmMergeObjectsPipe, CrmObjectValuePipe, CrmOmitPipe],
      exports: [CrmMergeObjectsPipe, CrmObjectValuePipe, CrmOmitPipe]
    }]
  }], null, null);
})();
class CrmResolveExpressionPipe {
  transform(resolvable, source, injector) {
    return crmResolveExpression({
      resolvable,
      source,
      injector
    });
  }
  static {
    this.ɵfac = function CrmResolveExpressionPipe_Factory(t) {
      return new (t || CrmResolveExpressionPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmResolveExpression",
      type: CrmResolveExpressionPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmResolveExpressionPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmResolveExpression'
    }]
  }], null, null);
})();
class CrmValueAsyncPipe {
  transform(value) {
    return wrapIntoObservable(value);
  }
  static {
    this.ɵfac = function CrmValueAsyncPipe_Factory(t) {
      return new (t || CrmValueAsyncPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmValueAsync",
      type: CrmValueAsyncPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmValueAsyncPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmValueAsync'
    }]
  }], null, null);
})();
/**
 * @deprecated
 */
class CrmValueAsyncPipeModule {
  static {
    this.ɵfac = function CrmValueAsyncPipeModule_Factory(t) {
      return new (t || CrmValueAsyncPipeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmValueAsyncPipeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmValueAsyncPipeModule, [{
    type: NgModule,
    args: [{
      imports: [CrmValueAsyncPipe],
      exports: [CrmValueAsyncPipe]
    }]
  }], null, null);
})();
class CrmIsString {
  transform(value) {
    return typeof value === 'string';
  }
  static {
    this.ɵfac = function CrmIsString_Factory(t) {
      return new (t || CrmIsString)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsString",
      type: CrmIsString,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsString, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsString'
    }]
  }], null, null);
})();
class CrmAsString {
  transform(value) {
    return value;
  }
  static {
    this.ɵfac = function CrmAsString_Factory(t) {
      return new (t || CrmAsString)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmAsString",
      type: CrmAsString,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmAsString, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmAsString'
    }]
  }], null, null);
})();
class CrmIsTemplatePipe {
  transform(value) {
    return value instanceof TemplateRef ? value : undefined;
  }
  static {
    this.ɵfac = function CrmIsTemplatePipe_Factory(t) {
      return new (t || CrmIsTemplatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsTemplate",
      type: CrmIsTemplatePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsTemplatePipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsTemplate'
    }]
  }], null, null);
})();
/**
 * @deprecated
 */
class CrmAsTemplatePipe {
  transform(value) {
    return value;
  }
  static {
    this.ɵfac = function CrmAsTemplatePipe_Factory(t) {
      return new (t || CrmAsTemplatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmAsTemplate",
      type: CrmAsTemplatePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmAsTemplatePipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmAsTemplate'
    }]
  }], null, null);
})();
class CrmIsNilPipe {
  transform(value) {
    return isNil(value);
  }
  static {
    this.ɵfac = function CrmIsNilPipe_Factory(t) {
      return new (t || CrmIsNilPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsNil",
      type: CrmIsNilPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsNilPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsNil'
    }]
  }], null, null);
})();
class CrmIsNotNilPipe {
  transform(value) {
    return !isNil(value);
  }
  static {
    this.ɵfac = function CrmIsNotNilPipe_Factory(t) {
      return new (t || CrmIsNotNilPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsNotNil",
      type: CrmIsNotNilPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsNotNilPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsNotNil'
    }]
  }], null, null);
})();
class CrmEnvConfigService {
  get environment() {
    return this._environment;
  }
  setConfig(val) {
    this._environment = val;
  }
  static {
    this.ɵfac = function CrmEnvConfigService_Factory(t) {
      return new (t || CrmEnvConfigService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmEnvConfigService,
      factory: CrmEnvConfigService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmEnvConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Configuration service to get or set configuration of crm features and parameters described
 * by AppConfiguration model.
 */
class CrmConfigService {
  constructor() {
    this.inputConfiguration = inject(CrmAppConfig, {
      optional: true
    });
    this.moduleConfigurations = inject(CrmModuleConfigToken, {
      optional: true
    });
    this.dynamicConfiguration = inject(CrmDynamicConfigToken, {
      optional: true
    });
    this.configuration = {};
    this.customConfig = {};
    this._config = {};
    this.initConfiguration(inject(CrmEnvConfigService).environment);
  }
  get config() {
    return this._config;
  }
  getConfig(path, defaultValue = {}) {
    return get(this._config, path, defaultValue);
  }
  mergeConfig(config, path, defaultValue = {}) {
    return mergeDeep(config, this.getConfig(path, defaultValue));
  }
  /**
   * Change crm configuration during runtime.
   * For example to change changelog modal, call `setConfig('modules.commonModule.features.changelogModal', true)`.
   * Changes will be set after rerender of page using this feature flag.
   */
  setConfig(path, value) {
    this.setCustomConfig(set(this.customConfig, path, value));
  }
  setCustomConfig(config) {
    this.customConfig = config;
    this.resolveConfig();
  }
  initConfiguration(envConfig) {
    let configuration = {
      modules: {}
    };
    if (this.inputConfiguration) {
      const config = mergeDeep({
        environment: {
          env: 'dev',
          production: false
        }
      }, this.inputConfiguration);
      configuration = mergeDeep(configuration, config);
    }
    if (envConfig) {
      configuration = mergeDeep(configuration, {
        environment: envConfig
      });
    }
    if (this.moduleConfigurations) {
      configuration.modules ??= {};
      this.moduleConfigurations.forEach(mc => configuration.modules = mergeDeep(configuration.modules ?? {}, mc));
    }
    if (this.dynamicConfiguration) {
      configuration = mergeDeep(configuration, this.dynamicConfiguration);
    }
    this.configuration = configuration;
    this.resolveConfig();
  }
  resolveConfig() {
    this._config = mergeDeep(cloneDeep(this.configuration), this.customConfig);
  }
  static {
    this.ɵfac = function CrmConfigService_Factory(t) {
      return new (t || CrmConfigService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmConfigService,
      factory: CrmConfigService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const CrmHttpParameterCodecToken = new InjectionToken('CrmHttpParameterCodecToken');
class CrmHttpParameterCodecService {
  decodeKey(key) {
    return decodeURIComponent(key);
  }
  decodeValue(value) {
    return decodeURIComponent(value);
  }
  encodeKey(key) {
    return encodeURIComponent(key);
  }
  encodeValue(value) {
    return encodeURIComponent(value);
  }
  static {
    this.ɵfac = function CrmHttpParameterCodecService_Factory(t) {
      return new (t || CrmHttpParameterCodecService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmHttpParameterCodecService,
      factory: CrmHttpParameterCodecService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmHttpParameterCodecService, [{
    type: Injectable
  }], null, null);
})();
const CrmNetworkClientToken = new InjectionToken('CrmNetworkClientToken');
class CrmNetworkClient {
  constructor() {
    this.http = inject(HttpClient);
  }
  delete(url, options) {
    return this.http.delete(url, options);
  }
  get(url, options) {
    return this.http.get(url, options);
  }
  head(url, options) {
    return this.http.head(url, options);
  }
  patch(url, body, options) {
    return this.http.patch(url, body, options);
  }
  post(url, body, options) {
    return this.http.post(url, body, options);
  }
  put(url, body, options) {
    return this.http.put(url, body, options);
  }
  request(method, url, options) {
    return this.http.request(method, url, options);
  }
  static {
    this.ɵfac = function CrmNetworkClient_Factory(t) {
      return new (t || CrmNetworkClient)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmNetworkClient,
      factory: CrmNetworkClient.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmNetworkClient, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CrmNetworkClientModule {
  static {
    this.ɵfac = function CrmNetworkClientModule_Factory(t) {
      return new (t || CrmNetworkClientModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmNetworkClientModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: CrmNetworkClientToken,
        useClass: CrmNetworkClient
      }, {
        provide: CrmHttpParameterCodecToken,
        useClass: CrmHttpParameterCodecService
      }],
      imports: [HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmNetworkClientModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule],
      providers: [{
        provide: CrmNetworkClientToken,
        useClass: CrmNetworkClient
      }, {
        provide: CrmHttpParameterCodecToken,
        useClass: CrmHttpParameterCodecService
      }]
    }]
  }], null, null);
})();
class CrmUrlParamsService {
  constructor() {
    this.params = signal({});
    this.debouncedParams = toSignal(toObservable(this.params).pipe(debounceTime(0)), {
      initialValue: {}
    });
    this.location = inject(Location);
    this.route = inject(ActivatedRoute);
    effect(() => this.updateQueryParams(this.debouncedParams()));
  }
  /**
   * Reset all params
   */
  reset() {
    this.params.set({});
  }
  /**
   * Clear params for given context
   *
   * @param context
   */
  clearParams(context) {
    this.updateParams(context, {});
  }
  /**
   * Update params for given context
   *
   * @param contextOrOptions
   * @param contextParams
   */
  updateContextParams(contextOrOptions, contextParams) {
    const reduce = _params => Object.entries(_params ?? {}).reduce((queryParams, [key, value]) => {
      if (isNil(value)) {
        return queryParams;
      }
      if (typeof value === 'number' || typeof value === 'boolean') {
        queryParams[key] = value;
      }
      if (typeof value === 'string' || Array.isArray(value)) {
        if (value.length > 0) {
          queryParams[key] = value;
        }
      }
      if (typeof value === 'object' && Object.keys(value).length > 0) {
        const reduced = reduce(value);
        if (Object.keys(reduced).length > 0) {
          queryParams[key] = reduced;
        }
      }
      return queryParams;
    }, {});
    if (typeof contextOrOptions === 'string') {
      this.updateParams(contextOrOptions, reduce(contextParams));
      return this.getContextParams(contextOrOptions);
    }
    const {
      context,
      strategy = 'overwrite',
      preserveParams,
      inputParams
    } = contextOrOptions;
    this.updateParams(context, this.resolveUpdateParams({
      newParams: reduce(inputParams),
      oldParams: this.getContextParams(context),
      strategy,
      preserveParams
    }));
    return this.getContextParams(context);
  }
  /**
   * Method for encoding JS object to URL params
   *
   * @example
   *
   * if input parameters are:
   *
   * context: 'foo'
   * params: {
   *   bar: {
   *     one: 1,
   *     two: 2
   *   }
   * }
   *
   * then this method encode input params with prefix of context ('foo')
   * {
   *   foo[bar][one]: 1,
   *   foo[bar][two]: 2
   * }
   * and return encoded object.
   *
   * @param context
   * @param params
   */
  encodeParams(context, params) {
    const encode = (key, obj) => {
      return Object.entries(obj).reduce((result, [k, v]) => {
        const newKey = `${key}[${k}]`;
        switch (typeof v) {
          case 'object':
            return {
              ...result,
              ...encode(newKey, v)
            };
          case 'number':
          case 'string':
            return {
              ...result,
              [newKey]: v
            };
        }
        return result;
      }, {});
    };
    return encode(context, params);
  }
  /**
   * Method for decoding URL params to JS object for given context
   *
   * @example
   * if input parameters are:
   *
   * context: 'foo'
   * params: {
   *   foo[bar][one]: 1,
   *   foo[bar][tow]: 2,
   *   bar[foo]: 3
   * }
   *
   * then this method parse only parameters starting with 'foo'
   * and merge parsed object to private _params
   * this._params = {
   *   foo: {
   *     bar: {
   *       one: 1,
   *       two: 2
   *     }
   *   }
   * }
   * and return parsed object.
   *
   * @param context
   * @param params
   */
  decodeParams(context, params) {
    if (isEmpty(params)) {
      this.clearParams(context);
    } else {
      const dotObject = Object.entries(params).filter(([k]) => this._isCurrentContext(context, k)).reduce((r, [k, v]) => {
        const match = k.match(/\[.*?\]/g);
        if (!match) {
          return r;
        }
        const dotKey = match.map(m => m.replace(/\[?\]?/g, '')).join('.');
        return {
          ...r,
          [dotKey]: v
        };
      }, {});
      this.updateParams(context, transformObject(dotObject));
    }
    return this.getContextParams(context);
  }
  /**
   * Return current stored params for given context
   *
   * @param context
   */
  getContextParams(context) {
    return this.params()[context];
  }
  /**
   * Check if param key is in given context
   *
   * @param context
   * @param key
   * @private
   */
  _isCurrentContext(context, key) {
    return key.substring(0, key.indexOf('[')) === context;
  }
  /**
   * Method for updating query params,
   * unknown URL parameters which don't belong to any context are persisted
   *
   * @param inputParams
   * @private
   */
  updateQueryParams(inputParams) {
    const contexts = Object.keys(inputParams);
    const encodedParams = Object.entries(inputParams).reduce((encoded, [key, value]) => ({
      ...encoded,
      ...this.encodeParams(key, value)
    }), {});
    const otherParams = Object.entries(this.route.snapshot.queryParams).reduce((result, [key, value]) => {
      if (contexts.some(context => this._isCurrentContext(context, key))) {
        return result;
      }
      return {
        ...result,
        [key]: value
      };
    }, {});
    const params = new HttpParams({
      fromObject: {
        ...otherParams,
        ...encodedParams
      }
    }).toString();
    this.location.replaceState(location.pathname, params);
  }
  /**
   * Resolve update params according to options
   *
   * @param options
   * @private
   */
  resolveUpdateParams(options) {
    const {
      newParams = {},
      oldParams = {},
      strategy = 'overwrite',
      preserveParams
    } = options;
    if (strategy === 'merge') {
      return {
        ...oldParams,
        ...newParams
      };
    }
    if (preserveParams) {
      return {
        ...pick(oldParams, preserveParams),
        ...newParams
      };
    }
    return newParams;
  }
  /**
   * Update inner params signal for given context
   *
   * @param context
   * @param queryParams
   * @private
   */
  updateParams(context, queryParams) {
    this.params.update(params => ({
      ...params,
      [context]: queryParams
    }));
  }
  static {
    this.ɵfac = function CrmUrlParamsService_Factory(t) {
      return new (t || CrmUrlParamsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmUrlParamsService,
      factory: CrmUrlParamsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmUrlParamsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmAppComponent, CrmAppConfig, CrmArrayPipesModule, CrmAsString, CrmAsTemplatePipe, CrmBreakpointSpanDirective, CrmBreakpointSpanModule, CrmBypassHtmlPipe, CrmConfigService, CrmControlValueAccessorDirective, CrmDisabledDirective, CrmDisabledDirectiveModule, CrmDynamicConfigToken, CrmElementIdPipe, CrmElementIdPipeModule, CrmEnvConfigService, CrmFilterPipe, CrmHttpParamTransform, CrmHttpParameterCodecService, CrmHttpParameterCodecToken, CrmInitialsPipe, CrmInitialsPipeModule, CrmIsLastPipe, CrmIsNilPipe, CrmIsNotNilPipe, CrmIsString, CrmIsTemplatePipe, CrmMergeObjectsPipe, CrmModuleConfigToken, CrmNetworkClient, CrmNetworkClientModule, CrmNetworkClientToken, CrmObjectValuePipe, CrmObjectsPipe, CrmOmitPipe, CrmOnceSubject, CrmResolveExpressionPipe, CrmReversePipe, CrmSharedSubject, CrmSlicePipe, CrmSliceRestPipe, CrmUnsubscribeDirective, CrmUrlParamsService, CrmValueAsyncPipe, CrmValueAsyncPipeModule, crmCollectResponse, crmCollectResponseMap, crmControlValueAccessorFactory, crmFilterNullish, crmIsMatchingString, crmKillEvent, crmRemovePunctuation, crmResolveExpression, crmResolveSignal, getNestedObject, joinArrayProperties, mergeDeep, toArray, toBoolean, transformObject, windowResize$ };
